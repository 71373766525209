<template>
    <app-navbar></app-navbar>

    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
</template>

<script>
    import Navbar from './components/navbar.vue'
    export default {
        name: 'App',
        data() {
            return {
                headTitle: ''
            }
        },
        components: {
            'app-navbar': Navbar
        }
    }
</script>