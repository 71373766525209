import {
    createApp
} from 'vue'
import App from './App.vue'

const app = createApp(App);

// 路由
import router from './router/index.js'
// pxtorem
import './util/rem.js'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import commonApi from './util/commonApi.js'
app.config.globalProperties.$commonApi = commonApi;

// 全局css
import './static/css/css.css'

app.use(router).use(ElementPlus).mount('#app')