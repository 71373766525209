import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index.vue'),
        meta:{
            title: '首页'
        }
    }
]


const router = createRouter({
    history: createWebHistory(),  // hash 模式，想要改为history 模式可以使用createWebHistory
    routes
})

export default router