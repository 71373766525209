<template>
    <div class="header">
        <img src="../static/img/title_top.png" class="title_bac title_top">
        <div class="title_bac title_bottom">
            <img src="../static/img/title_bottom.png" class="">
            <div class="guang_left"><img src="../static/img/guang.png" alt=""></div>
            <div class="guang_right"><img src="../static/img/guang.png" alt=""></div>
        </div>
        <div class="header_nr flex flex-sb">
            <div class="header_days">平台安全运营时间{{nowDays}}天</div>
            <div class="header_tit">朔州市工伤预防预警平台</div>
            <div class="header_time">{{nowDate}} {{nowWeek}} {{nowTime}} </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                nowDate: '',
                nowTime: '',
                nowWeek: '',
                nowDays: ''
            }
        },
        mounted() {
            this.currentTime();
            this.currentDays();
        },
        methods: {
            currentTime() {
                setInterval(this.formatDate, 500);
            },
            currentDays() {
                let startDate = '2024-04-01';
                let myDate = new Date()
                let yy = String(myDate.getFullYear());
                let mm = myDate.getMonth() + 1;
                let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
                let endDate = yy + '-' + mm + '-' + dd;
                setInterval(this.getDifferTime(startDate, endDate), 600000);
            },
            formatDate() {
                //获取当前时间并打印
                let myDate = new Date()
                let wk = myDate.getDay()
                let yy = String(myDate.getFullYear())
                let mm = myDate.getMonth() + 1
                let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
                let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
                let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
                let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
                let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
                let week = weeks[wk]
                this.nowDate = yy + ' 年 ' + mm + ' 月 ' + dd + ' 日'
                this.nowTime = hou + ':' + min + ':' + sec
                this.Time = hou + ':' + min
                this.nowWeek = week
            },
            getDifferTime(startDate, endDate) {
                const start = new Date(startDate);
                const end = new Date(endDate);
                const diffInMilliseconds = end - start; // 获取两个日期的毫秒差值
                const diffInDays = Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));
                this.nowDays = diffInDays;
            }
        },
    }
</script>